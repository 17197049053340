import {AREA_DEFAULT_OPTIONS, DONUT_DEFAULT_OPTIONS, LINE_DEFAULT_OPTIONS} from './default_charts_options';

const Chart = window.ApexCharts;

export default class Apexcharts {
  constructor(el, options) {
    this.el = el;
    this.chart = null;
    this.chartOptions = null;
    this.options = options;
    this.render();
  }

  render() {
    switch (this.options.chart.type) {
      case 'area':
        this.chartOptions = JSON.parse(JSON.stringify(AREA_DEFAULT_OPTIONS));
        break;
      case 'donut':
        this.chartOptions = JSON.parse(JSON.stringify(DONUT_DEFAULT_OPTIONS));
        break;
      case 'line':
        this.chartOptions = JSON.parse(JSON.stringify(LINE_DEFAULT_OPTIONS));
        break;
    }

    this.setOptions(this.chartOptions, this.options);
    this.chart = new Chart(this.el, this.chartOptions);
    this.chart.render();
  }

  setOptions(currentOptions, newOptions) {
    for (const key of Object.keys(newOptions)) {
      if (typeof currentOptions[key] === 'object' && !Array.isArray(currentOptions[key])) {
        this.setOptions(currentOptions[key], newOptions[key]);
      } else {
        currentOptions[key] = newOptions[key];
      }
    }
  }

  destroy() {
    this.chart.destroy();
  }
}
